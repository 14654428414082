<template>
  <v-autocomplete
    outlined
    :items="departments"
    :label="field.text"
    v-model="value"
    item-value="id"
    item-text="name"
    :rules="validators"
    append-icon="mdi-chevron-down"
    dense
  />
</template>

<script>
import inputMixin from '../../../mixins/InputMixin.vue'
import { mapState } from 'vuex'
import get from 'lodash/get'

export default {
  mixins: [inputMixin],
  computed: {
    ...mapState({
      departments: state => state.departments.items
    }),
    value: {
      get () {
        const currentValue = get(this.data, this.field.value, '')
        const suffix = this.field.value.split('.').pop()
        const currentObject = this.departments.find((type) => {
          return currentValue === type[suffix]
        })
        return currentObject ? currentObject.id : null
      },
      set (value) {
        const label = this.field.name
        this.$emit('update', { address: { [label]: value } })
      }
    },
  },
}
</script>
